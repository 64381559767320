import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import SvgAnalog from '../svg/SvgAnalog';

export default class AquasenseOverView extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false
      }
    }

  jsonGet(jsondata, key){
      try{
        return jsondata[key];
      }catch(error){
        //console.error(error);
        return {};
      }
    }

  getCurrentTime(){
    let dateObj = new Date();
    let month = (dateObj.getMonth() + 1);
    if(month < 10){
      month = "0" + month;
    }
    let date = dateObj.getDate();
    if(date < 10){
      date = "0" + date;
    }
    let hours = dateObj.getHours();
    if(hours < 10){
      hours = "0" + hours;
    }
    let minutes = dateObj.getMinutes();
    if(minutes < 10){
      minutes = "0" + minutes;
    }
    let seconds = dateObj.getSeconds();
    if(seconds < 10){
      seconds = "0" + seconds;
    }
    return dateObj.getFullYear() + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
  }

  /*Function to get the location string from a sysystem(key)*/
  getLocation(data, key) {
    const location = data[key];
    return location && location.hasOwnProperty("value") ? location.value : " ";
  }
  

  

  

  render() {
    let aqs0697ComError = this.props.listComErrorSystem.includes("aqs-06-97");
    let aqs0698ComError = this.props.listComErrorSystem.includes("aqs-06-98");
 
    let aqs0697redCrossWhenComError = <></>;
    if(aqs0697ComError){
      aqs0697redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M120 310 L505 560 M120 560 L505 310"/>
    }
    let aqs0698redCrossWhenComError = <></>;
    if(aqs0698ComError){
      aqs0698redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M550 310 L935 560 M550 560 L935 310"/>
    }

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    /* define the location name */
    const location0697 = this.getLocation(this.props.data, "aqs-06-97/system/location_name");
    const location0698 = this.getLocation(this.props.data, "aqs-06-98/system/location_name");

    return(

      <div style={{height:"100%", width:"100%",backgroundColor:backgroundColor}}>

        <div style={{width:"100%", height:"100%"}}>
         <svg  style={{ width:"100%",height:"100%"}} viewBox="0 0 1920 1080" xmlns="http://www.w3.org/2000/svg">
            <text x="960" y="50" fill={textColor} style={{fontSize:"35",alignmentBaseline:"middle",textAnchor:"middle"}} >Overview</text>
            <text x="1900" y="100" fill={textColor} style={{fontSize:"25",alignmentBaseline:"end",textAnchor:"end"}} >{this.getCurrentTime()}</text>

            {/* Border for AQS-06-97 and NH3-01-02  {this.props.data["aqs-06-97/system/location_name"].value} */} 
            <text x="312.5" y="200" fill={textColor} style={{fontSize:"25",alignmentBaseline:"middle",textAnchor:"middle"}} >{location0697}</text>

            <text x="312.5" y="245" fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-97</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x="120" y="260" width="385" height="350" ry="20"/>
  
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={185} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-97/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-24} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={310} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-97/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-24} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={435} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-97/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-24} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={310} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-97/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-24} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={185} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-97/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-24} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={310} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-97/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-24} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={435} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-97/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-24} alarmTextAlignmentBaseline="middle"/>  
            <SvgAnalog objectName="TURB" objectDescription="turbidity" pvMin={0.0} pvMax={200.0} x={185} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-97/analog/optional_sensor4"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-24} alarmTextAlignmentBaseline="middle"
             unit_of_measurement={"NTU"}/>

            {aqs0697redCrossWhenComError}


            {/* Border for AQS-06-98 and NH3-01-03 {this.props.data["aqs-06-98/system/location_name"].value} */}
            <text x="742.5" y="200" fill={textColor} style={{fontSize:"25",alignmentBaseline:"middle",textAnchor:"middle"}} >{location0698}</text>

            <text x="742.5" y="245" fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-98</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x="550" y="260" width="385" height="350" ry="20"/>
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={615} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-98/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-24} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={740} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-98/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-24} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={865} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-98/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-24} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={740} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-98/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-24} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={615} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-98/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-24} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={740} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-98/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-24} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={865} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-98/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-24} alarmTextAlignmentBaseline="middle"/>  
            <SvgAnalog objectName="TURB" objectDescription="turbidity" pvMin={0.0} pvMax={200.0} x={615} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-98/analog/optional_sensor4"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-24} alarmTextAlignmentBaseline="middle"
             unit_of_measurement={"NTU"} fontSize="2" />

            {aqs0698redCrossWhenComError}

          </svg>
        </div>
      </div>
    )

    }

}
