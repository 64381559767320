import React from "react"; 
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import {getServerUrl} from '../ApplicationNetworking'

export default class TextEntryFieldModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      modalWidth: 300,
      modalHeight: 400,
      newValue: "",
      responseText: "",
      valueChangeSuccess: false,
      positionOffsetX: window.innerWidth / 2,
      positionOffsetY: window.innerHeight / 2,
      isDragging: false,
      dragCursorOffset: null
    }
  }

  componentDidMount() {
    this.setModalDimensions();
    window.addEventListener('resize', this.setModalDimensions);
  }

  componentDidUpdate(previousProps) {
    if (this.props.data.currentValue !== previousProps.data.currentValue) {
      this.setState({ newValue: this.props.data.currentValue });
    }
    if (previousProps.show && !this.props.show) {
      this.resetWindowToCenter();
    }
  }

  setModalDimensions = () => {
    let width = "250px";
    if (window.innerWidth < 767) {
      width = window.innerWidth * 0.6;
    }
    let height = "330px";
    if (window.innerHeight < 767) {
      height = window.innerHeight * 0.65;
    }
    this.setState({ modalWidth: width, modalHeight: height });
    this.resetWindowToCenter();
  }

  resetWindowToCenter = () => {
    this.setState({ positionOffsetX: window.innerWidth / 2, positionOffsetY: window.innerHeight / 2 });
  }

  dragWindow = (event) => {
    event.preventDefault();
    let cursorOffsetX = 0;
    let cursorOffsetY = 0;
    if (!this.state.dragCursorOffset) {
      this.setState({ dragCursorOffset: [event.clientX - this.state.positionOffsetX, event.clientY - this.state.positionOffsetY] });
      cursorOffsetX = event.clientX - this.state.positionOffsetX;
      cursorOffsetY = event.clientY - this.state.positionOffsetY;
    } else {
      cursorOffsetX = this.state.dragCursorOffset[0];
      cursorOffsetY = this.state.dragCursorOffset[1];  
    }
    if (event.clientX > 0 && event.clientY > 0) { 
      let newPosX = event.clientX - cursorOffsetX;
      if (newPosX > window.innerWidth - this.state.modalWidth / 2) {
        newPosX = window.innerWidth - this.state.modalWidth / 2;
      } else if (newPosX < this.state.modalWidth / 2) {
        newPosX = this.state.modalWidth / 2;
      }
      let newPosY = event.clientY - cursorOffsetY;
      if (newPosY > window.innerHeight - this.state.modalHeight / 2) {
        newPosY = window.innerHeight - this.state.modalHeight / 2;
      } else if (newPosY < this.state.modalHeight / 2) {
        newPosY = this.state.modalHeight / 2;
      }
      this.setState({ positionOffsetX: newPosX, positionOffsetY: newPosY, isDragging: true });
    }
  }

  resetIsDragging = () => {
    this.setState({ isDragging: false, dragCursorOffset: null });
  }

  insertEvent = (eventDescription) => {
    let { objectName, objectDescription, unitName, userName } = this.props.data;
    if (eventDescription && objectName && objectDescription && unitName && userName) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          "event_object_name": objectName,
          "event_object_description": objectDescription,
          "event_description": eventDescription,
          "system_name": unitName.toUpperCase(),
          "operator_name": userName,
          "event_from_operator": true
        })
      };
      let url = getServerUrl() + "insertevent?auth_token=" + this.props.auth_token;
      fetch(url, requestOptions)
        .then(response => response.ok ? response.json() : Promise.reject(response))
        .catch(() => this.setState({ isLoading: false, error: true }));
    }
  }

  handleChangeNewValue = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        "topic": this.props.data.dataPath,
        "payload": { "value": this.state.newValue }
      })
    };
    let url = getServerUrl() + "sendvalue?auth_token=" + this.props.auth_token;
    fetch(url, requestOptions)
      .then(response => response.ok ? response.json() : Promise.reject(response))
      .then(() => {
        this.setState({ responseText: "Value changed!", valueChangeSuccess: true });
        setTimeout(this.closeModal, 2000);
        let eventText = `Value change from: ${this.props.data.currentValue} to ${this.state.newValue}`;
        this.insertEvent(eventText);
      })
      .catch(() => this.setState({ isLoading: false, error: true, responseText: "Error updating value!" }));
  }

  closeModal = () => {
    this.props.close(false);
    if (this.props.data.currentValue !== undefined) {
      this.setState({ newValue: this.props.data.currentValue, valueChangeSuccess: false, responseText: "" });
    }
  }

  handleNewValueOnChange = (event) => {
    this.setState({ newValue: event.target.value });
  }

  render() {
    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let modalStyle = {
      position: "absolute",
      height: this.state.modalHeight,
      width: this.state.modalWidth,
      left: this.state.positionOffsetX,
      top: this.state.positionOffsetY,
      transform: "translate(-50%, -50%)",
      backgroundColor: backgroundColor,
      border: this.props.colorTheme() === "light" ? "5px solid #053d57" : "2px solid #f2f2f2",
      borderRadius: "10px",
      zIndex: "2",
      padding: "5px"
    };

    let currentValue = this.props.data.currentValue;

    let responseTextClass = "text-center text-success";
    if (!this.state.valueChangeSuccess && this.state.responseText.length > 0) {
      responseTextClass = "text-center text-danger";
    }

    let applyButton = <button disabled type="button" className="btn btn-secondary btn-block mb-4">Apply</button>;
    if (this.props.data.currentValue !== this.state.newValue) {
      applyButton = <button type="button" className="btn btn-primary btn-block mb-4" onClick={this.handleChangeNewValue}>Apply</button>;
    }

    if (this.props.show) {
      return (
        <div style={modalStyle}>
          <div className="container-fluid">

            <div draggable="true" style={{ cursor: "move" }} onDrag={this.dragWindow} onDragEnd={this.resetIsDragging} className="row">
              <div className="col-sm-12">
                <button className="close-button" style={{ backgroundColor: backgroundColor, float: "right", borderRadius: "5px", borderColor: textColor }} onClick={this.closeModal}>
                  <span style={{ color: this.props.colorTheme() === "light" ? "black" : "white" }} aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="text-center">
                  <p style={{ margin: 0, color: textColor }}>{this.props.data.unitName?.toUpperCase()}</p>
                  <p style={{ margin: 0, color: textColor }}><small>{this.props.data.objectName} {this.props.data.objectDescription}</small></p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className={responseTextClass}>
                  <h5>{this.state.responseText}</h5>
                </div>
              </div>
            </div>

            <form>
              <div className="container">

                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-outline mb-2">
                      <label style={{ color: textColor }} className="form-label" htmlFor="inputNewValue">New value:</label>
                      <input style={{ backgroundColor: backgroundColor, color: textColor }} type="text" id="inputNewValue" className="form-control" onChange={this.handleNewValueOnChange} value={this.state.newValue} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="text-center">
                      {applyButton}
                    </div>
                  </div>
                </div>
              </div>
            </form>

          </div> {/* container */}
        </div>
      )
    } else {
      return null;
    }
  }
}
