import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'

import NumericEntryFieldModal from './NumericEntryFieldModal'

export default class AnalogModal extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        positionOffsetX:window.innerWidth/2,
        positionOffsetY:window.innerHeight/2,
        modalWidth:0,
        modalHeight:0,
        isDragging:false,
        showNumericFieldModal:false,
        numericFieldModalData:{},
        dragOffsets:null
      }
  }

  componentDidMount() {
    this.setModalDimensions();
    window.addEventListener('resize', this.setModalDimensions);
  }

  componentDidUpdate(previousProps) {
    // Reset the window when its closed so it will be centered at the screen once its opens again.
    if(previousProps.show && !this.props.show){
      this.setModalDimensions()
    }
  }

  setModalDimensions = () => {
    let width = "380px";
    if(window.outerWidth < 480){
      width = parseInt(window.outerWidth*0.8) + "px";
    }
    let height = "400px";
    if(window.innerHeight < 480){
      height = parseInt(window.innerHeight*0.8) + "px";
    }
    this.setState({ modalWidth: width, modalHeight:height});
    this.resetWindowToCenter();
  }

  resetWindowToCenter = () => {
    this.setState({positionOffsetX:window.innerWidth/2, positionOffsetY:window.innerHeight/2});
  }

  closeModal = () => {
    this.props.close(false);
    this.setState({showNumericFieldModal:false});
  }

  openNumericFieldModal = (jsonData) => {
    this.setState({showNumericFieldModal:true, numericFieldModalData:jsonData});
  }

  closeNumericFieldModal = () => {
    this.setState({showNumericFieldModal:false});
  }

  dragWindow = (event) => {
    event.preventDefault();
    let cursorOffsetX = 0;
    let cursorOffsetY = 0;
    if(!this.state.dragCursorOffset){
      this.setState({dragCursorOffset:[event.clientX - this.state.positionOffsetX,event.clientY - this.state.positionOffsetY]});
      cursorOffsetX = event.clientX - this.state.positionOffsetX;
      cursorOffsetY = event.clientY - this.state.positionOffsetY;
    }
    else{
      cursorOffsetX = this.state.dragCursorOffset[0];
      cursorOffsetY = this.state.dragCursorOffset[1];  
    }
    if(event.clientX > 0 &&  event.clientY > 0){ 

      let newPosX = event.clientX - cursorOffsetX;
      // Limit X position inside window.
      if(newPosX > window.innerWidth - this.state.modalWidth/2){
        newPosX = window.innerWidth - this.state.modalWidth/2;
      }
      else if (newPosX < this.state.modalWidth/2){
        newPosX = this.state.modalWidth/2;
      }
      let newPosY = event.clientY - cursorOffsetY
      // Limit Y position inside window.
      if(newPosY > window.innerHeight - this.state.modalHeight/2){
        newPosY = window.innerHeight - this.state.modalHeight/2;
      }
      else if (newPosY < this.state.modalHeight/2){
        newPosY = this.state.modalHeight/2;
      }
      this.setState({positionOffsetX:newPosX, positionOffsetY:newPosY, isDragging:true});
    }
  }

  resetIsDragging = () =>{
    this.setState({isDragging:false, dragCursorOffset:null});
  }

  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let modalStyle = {
        position:"absolute",
        height: this.state.modalHeight,
        width: this.state.modalWidth,
        left: this.state.positionOffsetX,
        top: this.state.positionOffsetY,
        transform: "translate(-50%, -50%)",
        backgroundColor: backgroundColor,
        border: this.props.colorTheme() === "light" ? "5px solid #053d57" : "2px solid #f2f2f2",
        borderRadius: "10px",
        zIndex:"1",
        padding:"5px"
    };

    let styleParameters = {
          fontSize:"1.3rem",
          width:"100%", 
          height:"auto",
          marginLeft:"15px"
    };


    let styleParametersWithDarkMode = {
          fontSize:"1.3rem",
          width:"100%", 
          height:"auto",
          marginLeft:"15px",
          color:textColor
    };

    let hhDiv = <div className="border rounded">
                  <i style={styleParameters} >High high alarm limit: No data! </i>
                </div>;
    let hhPv = NaN;
    let hhPvEngUnit = "";
    let hDiv =  <div className="border rounded">
                  <i style={styleParameters} >High alarm limit: No data! </i>
                </div>;
    let hPv = NaN;
    let hPvEngUnit = "";
    let lDiv = <div className="border rounded">
                  <i style={styleParameters} >Low alarm limit: No data! </i>
                </div>;
    let lPv = NaN;
    let lPvEngUnit = "";
    let llDiv = <div className="border rounded">
                  <i style={styleParameters} >Low low alarm limit: No data! </i>
                </div>;
    let llPv = NaN;
    let llPvEngUnit = "";
    let alarmDelayDiv = <div className="border rounded">
                  <i style={styleParameters} >Alarm delay: No data! </i>
                </div>;
    let alarmDelayPv = NaN;
    let alarmDelayEngUnit = "";
    let alarmHysteresisDiv = <div className="border rounded">
                  <i style={styleParameters} >Alarm hysteresis: No data! </i>
                </div>;
    let alarmHysteresisPv = NaN;
    let alarmHysteresisEngUnit = "";

    let offsetDiv = <div className="border rounded">
    <i style={styleParameters} >Offset: No data! </i>
  </div>;
    let offsetPv = NaN;
    let offsetEngUnit = "";

    if(this.props.data !== undefined){
      if(this.props.data.hasOwnProperty(this.props.dataPath + "/hh")){
        hhPv = this.props.data[this.props.dataPath + "/hh"].value;
        hhPvEngUnit = this.props.data[this.props.dataPath + "/hh"].unit_of_measurement;
        hhDiv = <div style={{backgroundColor:"red"}} className="border rounded">
                  <i style={styleParameters} >High high alarm limit: {hhPv} {hhPvEngUnit} 
                    <FontAwesomeIcon className="float-end" style={{cursor:"pointer", marginRight:"10px"}} icon={faPencil} onClick={() => this.openNumericFieldModal(
                    {"dataPath":this.props.dataPath+"/hh/set","currentValue":hhPv,"unitOfMeasurement":hhPvEngUnit, 
                     "objectName":this.props.customProps.objectName, "objectDescription":this.props.customProps.objectDescription + ", alarm limit high high",
                     "min":this.props.customProps.pvMin, "max":this.props.customProps.pvMax, "unitName":this.props.unitName, "userName":this.props.customProps.userName})}/>
                  </i>
                </div>;
      }
      
      if(this.props.data.hasOwnProperty(this.props.dataPath + "/h")){
        hPv = this.props.data[this.props.dataPath + "/h"].value;
        hPvEngUnit = this.props.data[this.props.dataPath + "/h"].unit_of_measurement;
        hDiv = <div style={{backgroundColor:"yellow"}} className="border rounded">
                  <i style={styleParameters} >High alarm limit: {hPv} {hPvEngUnit} 
                    <FontAwesomeIcon className="float-end" style={{cursor:"pointer", marginRight:"10px"}} icon={faPencil} onClick={() => this.openNumericFieldModal(
                    {"dataPath":this.props.dataPath+"/h/set","currentValue":hPv,"unitOfMeasurement":hPvEngUnit, 
                     "objectName":this.props.customProps.objectName, "objectDescription":this.props.customProps.objectDescription + ", alarm limit high",
                     "min":this.props.customProps.pvMin, "max":this.props.customProps.pvMax, "unitName":this.props.unitName, "userName":this.props.customProps.userName})}/>
                  </i>
                </div>;
      }
      
      if(this.props.data.hasOwnProperty(this.props.dataPath + "/l")){
        lPv = this.props.data[this.props.dataPath + "/l"].value;
        lPvEngUnit = this.props.data[this.props.dataPath + "/l"].unit_of_measurement;
        lDiv = <div style={{backgroundColor:"yellow"}} className="border rounded">
                  <i style={styleParameters} >Low alarm limit: {lPv} {lPvEngUnit} 
                    <FontAwesomeIcon className="float-end" style={{cursor:"pointer", marginRight:"10px"}} icon={faPencil} onClick={() => this.openNumericFieldModal(
                    {"dataPath":this.props.dataPath+"/l/set","currentValue":lPv,"unitOfMeasurement":lPvEngUnit, 
                     "objectName":this.props.customProps.objectName, "objectDescription":this.props.customProps.objectDescription + ", alarm limit low",
                     "min":this.props.customProps.pvMin, "max":this.props.customProps.pvMax, "unitName":this.props.unitName, "userName":this.props.customProps.userName})}/>
                  </i>
                </div>;
      }
      
      if(this.props.data.hasOwnProperty(this.props.dataPath + "/ll")){
        llPv = this.props.data[this.props.dataPath + "/ll"].value;
        llPvEngUnit = this.props.data[this.props.dataPath + "/ll"].unit_of_measurement;
        llDiv = <div style={{backgroundColor:"red"}} className="border rounded">
                  <i style={styleParameters} >Low low alarm limit: {llPv} {llPvEngUnit} 
                    <FontAwesomeIcon className="float-end" style={{cursor:"pointer", marginRight:"10px"}} icon={faPencil} onClick={() => this.openNumericFieldModal(
                    {"dataPath":this.props.dataPath+"/ll/set","currentValue":llPv,"unitOfMeasurement":llPvEngUnit, 
                     "objectName":this.props.customProps.objectName, "objectDescription":this.props.customProps.objectDescription + ", alarm limit low low",
                     "min":this.props.customProps.pvMin, "max":this.props.customProps.pvMax, "unitName":this.props.unitName, "userName":this.props.customProps.userName})}/>
                  </i>
                </div>;
      }
      
      if(this.props.data.hasOwnProperty(this.props.dataPath + "/delay")){
        alarmDelayPv = this.props.data[this.props.dataPath + "/delay"].value;
        alarmDelayEngUnit = this.props.data[this.props.dataPath + "/delay"].unit_of_measurement;
        alarmDelayDiv = <div className="border rounded">
                          <i style={styleParametersWithDarkMode} >Alarm delay: {alarmDelayPv} {alarmDelayEngUnit} 
                            <FontAwesomeIcon className="float-end" style={{cursor:"pointer", marginRight:"10px"}} icon={faPencil} onClick={() => this.openNumericFieldModal(
                            {"dataPath":this.props.dataPath+"/delay/set","currentValue":alarmDelayPv,"unitOfMeasurement":alarmDelayEngUnit, 
                             "objectName":this.props.customProps.objectName, "objectDescription":this.props.customProps.objectDescription + ", alarm delay",
                             "min":0, "max":300, "unitName":this.props.unitName, "userName":this.props.customProps.userName})}/>
                          </i>
                        </div>;
      }
      
      if(this.props.data.hasOwnProperty(this.props.dataPath + "/alarm_hysteresis")){
        alarmHysteresisPv = this.props.data[this.props.dataPath + "/alarm_hysteresis"].value;
        alarmHysteresisEngUnit = this.props.data[this.props.dataPath + "/alarm_hysteresis"].unit_of_measurement;
        alarmHysteresisDiv = <div className="border rounded">
                              <i style={styleParametersWithDarkMode} >Alarm hysteresis: {alarmHysteresisPv} {alarmHysteresisEngUnit} 
                                <FontAwesomeIcon className="float-end" style={{cursor:"pointer", marginRight:"10px"}} icon={faPencil} onClick={() => this.openNumericFieldModal(
                                {"dataPath":this.props.dataPath+"/alarm_hysteresis/set","currentValue":alarmHysteresisPv,"unitOfMeasurement":alarmHysteresisEngUnit, 
                                 "objectName":this.props.customProps.objectName, "objectDescription":this.props.customProps.objectDescription + ", alarm hysteresis",
                                 "min":0, "max":10, "unitName":this.props.unitName, "userName":this.props.customProps.userName})}/>
                              </i>
                            </div>;
      }

      if(this.props.data.hasOwnProperty(this.props.dataPath + "/offset")){
        offsetPv = this.props.data[this.props.dataPath + "/offset"].value;
        console.log(this.props.data[this.props.dataPath+ "/offset"]);
        offsetEngUnit = this.props.data[this.props.dataPath + "/offset"].unit_of_measurement;
        if(offsetEngUnit === "ph" || offsetEngUnit === "deg.c"){
          offsetDiv = <div className="border rounded">
                                <i style={styleParametersWithDarkMode} >Process value offset: {offsetPv} {offsetEngUnit} 
                                  <FontAwesomeIcon className="float-end" style={{cursor:"pointer", marginRight:"10px"}} icon={faPencil} onClick={() => this.openNumericFieldModal(
                                  {"dataPath":this.props.dataPath+"/offset/set","currentValue":offsetPv,"unitOfMeasurement":offsetEngUnit, 
                                  "objectName":this.props.customProps.objectName, "objectDescription":this.props.customProps.objectDescription + ", offset",
                                  "unitName":this.props.unitName, "userName":this.props.customProps.userName})}/>
                                </i>
                              </div>;
        }
        else{
          offsetDiv = null
        }
      }   
    }


    if(this.props.show){
      return(
      <div>
      <NumericEntryFieldModal colorTheme={this.props.colorTheme} auth_token={this.props.auth_token} show={this.state.showNumericFieldModal} close={this.closeNumericFieldModal} data={this.state.numericFieldModalData}/>

      <div style={modalStyle}>

        <div className="container">

          <div draggable="true" style={{cursor:"move"}} onDrag={(event) => this.dragWindow(event)} onDragEnd={this.resetIsDragging} className="row">
            <div className="col-sm-12">
              <button className="close-button" style={{backgroundColor:backgroundColor,float:"right", borderRadius:"5px", borderColor:textColor}} onClick={() => this.closeModal()}>
                <span style={{color:this.props.colorTheme() === "light" ? "black" : "white"}} aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <h5 style={{color:textColor}}>{this.props.unitName.toUpperCase()}</h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <h5 style={{color:textColor}}>{this.props.customProps.objectName} - {this.props.customProps.objectDescription}</h5>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 my-1">
              {hhDiv} 
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 my-1">
              {hDiv}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 my-1">
              {lDiv}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 my-1">
              {llDiv}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 my-1">
              {alarmDelayDiv}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 my-1">
              {alarmHysteresisDiv}
            </div>
          </div>
          
          <div className="row">
            <div className="col-sm-12 my-1">
              {offsetDiv}
            </div>
          </div>

        </div> {/* container */}

      </div> 
      </div>
    )}
    else{
      return(
        <div></div>
      )
    }
  }

}
